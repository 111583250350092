import styled from "@emotion/styled";

import font from "./noteworthy.ttf";

export const DISPLAY_SIZES = {
  MOBILE_S: "320px",
  MOBILE_M: "375px",
  MOBILE_L: "425px",
  TABLET: "768px",
  LAPTOP: "1024px",
  LAPTOP_L: "1440px",
  DESKTOP: "1920px",
  DESKTOP_L: "2560px",
  DESKTOP_XL: "3840px",
};

export const Colors = {
  BLUE: "#0052FF",
  WHITE: "#FFF",
  BLACK: "#000",
  YELLOW: "#f1c40f",
  ORANGE: "#f39c12",
  RED: "#d35400",
};

export const Container = styled.div`
  background-color: ${Colors.YELLOW};
  color: white;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  /* font-family: "Preahvihear", sans-serif; */
  font-family: "Noteworthy", sans-serif;

  @font-face {
    font-family: "Noteworthy";
    src: url(${font}) format("truetype");
  }
`;

export const YellowBoiImg = styled.img`
  height: 40vh;
  animation: App-logo-spin infinite 2s ease-in;

  @keyframes App-logo-spin {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(30deg);
    }

    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-30deg);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-height: 100vh;

  padding: 32px 80px;
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
    padding: 32px 16px;
  }
`;

export const Label = styled.div`
  font-size: 64px;
  letter-spacing: 4px;
  font-weight: 600;
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 80px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const SocialLogoContainer = styled.div`
  /* height: 160px;
  width: 160px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 80px;
    width: 80px;
  }
`;

export const SocialLogo = styled.img<{ dexscreener?: boolean }>`
  height: 80px;
  width: 80px;
  transition: 0.2s border-width, 0.2s border-color, 0.2s opacity, 0.2s height,
    0.2s width;
  border-radius: 50%;
  cursor: pointer;
  border: 0px solid ${Colors.ORANGE};
  box-sizing: border-box;

  ${({ dexscreener }) =>
    dexscreener
      ? `
    background: ${Colors.BLACK};
    padding: 16px;
    box-sizing: border-box;
  `
      : ""}

  /* filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3)); */

  /* :hover {
    height: 166px;
    width: 166px;
    border-width: 8px;
  }

  :active {
    height: 140px;
    width: 140px;
    opacity: 0.8;
    border-width: 8px;
    border-color: ${Colors.RED};
  } */

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 75px;
    width: 75px;

    :hover {
      height: 80px;
      width: 80px;
    }

    :active {
      height: 70px;
      width: 70px;
    }
  }
`;

export const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 100%;
  }
`;

export const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 100%;
  }
`;

export const UniswapIFrame = styled.iframe`
  border: 0;
  margin: 16px auto;
  margin-bottom: 0.5rem;
  display: block;
  border-radius: 10px;
  width: 100%;
`;

export const Button = styled.button<{ small?: boolean }>`
  font-family: Handjet, sans-serif;
  border-radius: 200px;
  padding: 8px 32px 16px;
  font-size: 48px;
  font-weight: 700;

  position: relative;
  box-sizing: border-box;
  line-height: 150%;
  font-family: "Noteworthy", sans-serif;
  cursor: pointer;
  transition: 0.2s background-color, 0.2s color, 0.2s transform;
  border: 2px solid ${Colors.WHITE};
  color: ${Colors.WHITE};

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  user-select: none;
  letter-spacing: 2px;

  :hover {
    background-color: ${Colors.ORANGE};
  }

  :active {
    /* background-color: ${Colors.BLACK};
    color: ${Colors.WHITE}; */
    transform: scale(0.95);
  }

  ${({ small }) =>
    small
      ? `
    padding: 0 16px 0 0;
    gap: 8px;
    font-size: 32px;
  `
      : ""}
`;
