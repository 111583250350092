import React from "react";
import logo from "./yellowboi.webp";
import "./App.css";
import {
  Button,
  Container,
  Content,
  Label,
  Left,
  Right,
  SocialLogo,
  SocialLogoContainer,
  Socials,
  UniswapIFrame,
  YellowBoiImg,
} from "./App.styles";

import twitter from "./twitter.png";
import tg from "./tg.png";
import dexscreener from "./dexscreener.svg";

const TWITTER_LINK = "https://twitter.com/yellowboicoin";
const TELEGRAM_LINK = "https://t.me/yellowboiportal";
const DEXSCREENER_LINK =
  "https://dexscreener.com/ethereum/0x62cc0eff09ad315da4ac8d5feb2bd27db4cddc8e";
const BUY_NOW_LINK =
  "https://app.uniswap.org/#/swap?exactField=input&exactAmount=4.20&inputCurrency=ETH&outputCurrency=0x62cc0eff09ad315da4ac8d5feb2bd27db4cddc8e";

function App() {
  const onBuyNow = () => {
    window.open(BUY_NOW_LINK, "_blank");
  };

  return (
    <Container>
      <Content>
        <Left>
          <YellowBoiImg src={logo} />
          <Label>yellow boi</Label>

          <Socials>
            <Button
              small
              onClick={() => window.open(DEXSCREENER_LINK, "_blank")}
            >
              <SocialLogoContainer>
                <SocialLogo dexscreener src={dexscreener} />
              </SocialLogoContainer>
              Dexscreener
            </Button>
            <Button small onClick={() => window.open(TWITTER_LINK, "_blank")}>
              <SocialLogoContainer>
                <SocialLogo src={twitter} />
              </SocialLogoContainer>
              Twitter
            </Button>
            <Button small onClick={() => window.open(TELEGRAM_LINK, "_blank")}>
              <SocialLogoContainer>
                <SocialLogo src={tg} />
              </SocialLogoContainer>{" "}
              Telegram
            </Button>
          </Socials>
        </Left>

        <Right>
          <UniswapIFrame
            title="uniswap"
            src={BUY_NOW_LINK}
            height="660px"
            width="100%"
          />

          <Button onClick={onBuyNow}>buy now</Button>
        </Right>
      </Content>
    </Container>
  );
}

export default App;
